import React from 'react';
import { navigate } from 'gatsby';
import { isAuthenticated } from '../utils';

const IndexPage = () => {
  if (typeof window === 'undefined') {
    console.log('IndexPage no window');
    return null;
  }

  if (isAuthenticated()) {
    navigate('/home');
    return <h1>Signing in...</h1>;
  }

  navigate('/login');
  return <h1>Loading...</h1>;
};

export default IndexPage;
